<template>
  <div class="home has-text-centered">
    <h1 class="title is-1 has-text-cursive">Event Details</h1>
    <br />
    <div style="margin: 1.5em">
      <div class="columns" style="border: 1px solid gray; border-radius: 1em">
        <div class="column is-one-third">
          <img src="../../public/RingIcon.png" width="60" height="60" />
          <br />
          <h4 class="title is-2 has-text-cursive">What</h4>
          <p>The wedding of</p>
          <p>Korra-Shay Richards and</p>
          <p>Matthew Manoleras</p>
        </div>

        <div class="column is-one-third">
          <img src="../../public/ClockIcon.png" width="60" height="60" />
          <br />
          <h4 class="title is-2 has-text-cursive">When</h4>
          <p>Saturday June 25, 2022</p>
          <p>Ceremony: 4:30pm</p>
          <p>Cocktail hour & reception to immediately follow</p>
        </div>

        <div class="column is-one-third">
          <img src="../../public/MapIcon.png" width="60" height="60" />
          <br />
          <h4 class="title is-2 has-text-cursive">Where</h4>
          <p>The Edison</p>
          <p>777 N 4th St, Columbus, OH 43215</p>
          <a href="https://edison777.biz" class="gold-link"
            >https://edison777.biz</a
          >
        </div>
      </div>
    </div>

    <h2 class="title is-1 is-subtitle has-text-cursive">Welcome Dinner</h2>
    <p>Friday June 24th, 2022</p>
    <router-link
      to="WelcomeDinner"
    >
      <a class="button is-dark" style="margin-bottom:1rem;"
        >Click Here for Welcome Dinner Information</a
      >
    </router-link>

    <img src="../../public/Edison.webp" />

    <div class="columns">
      <div class="column is-one-half">
        <img src="../../public/Columbus.jpg" />
      </div>
      <div class="column is-one-half">
        <img src="../../public/Dublin.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
};
</script>

<style scoped>
p {
  margin-bottom: 0.15em;
}
</style>
