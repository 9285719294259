<template>
  <div>
    <h1 class="title is-1 has-text-centered has-text-cursive">
      Engagement Photos
    </h1>
    <br />
    <div class="has-text-centered">
      <blockquote
        class="imgur-embed-pub"
        lang="en"
        data-id="a/NaO6RWi"
        data-context="false"
      >
        <a href="//imgur.com/a/NaO6RWi">Wedding: Engagement Pics</a>
      </blockquote>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "https://s.imgur.com/min/embed.js");
    document.head.appendChild(externalScript);
  },
};
</script>