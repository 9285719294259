<template>
  <div>
    <input v-show="!canViewStatus" v-model="statusPassword" type="text" />
    <table v-if="canViewStatus" class="table">
      <thead>
        <tr>
          <th>Page</th>
          <th>User Agent</th>
          <th>Timestamp</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(status, index) in statusEvents" :key="index">
          <td>{{ status.page }}</td>
          <td>{{ status.userAgent }}</td>
          <td>{{ status.date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ApiService } from "../services/apiService";

export default {
  data() {
    return {
      statusPassword: "",
      canViewStatus: false,
      statusEvents: [],
    };
  },
  created() {
    ApiService.getEvents().then((result) => {
      this.statusEvents = result.data;
    });
  },
  watch: {
    statusPassword() {
      if (this.statusPassword == "PuppyCuddles!") {
        this.canViewStatus = true;
      }
    },
  },
};
</script>