<template>
  <div>
    <div class="card">
      <div class="columns">
        <div class="column is-4">
          <div class="card-image">
            <figure class="image max-height:250px;">
              <img :src="src" :alt="src" />
            </figure>
          </div>
        </div>
        <div class="column is-8">
          <div class="card-content">
            <div class="content">{{ text }}</div>
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "ParagraphPhoto",
  props: ["src", "text", "isOdd"],
};
</script>