<template>
  <div class="has-text-centered">
    <h1 class="has-text-cursive title is-1">Columbus Travel</h1>
    <br />
    <h2 class="title is-2 is-subtitle">Hotel Block</h2>
    <a
      @click="clickHotelLink"
      class="button is-outline is-dark is-large"
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.marriott.com/events/start.mi?id=1628783150742&key=GRP"
      >Click to Book</a
    >
  </div>

  <h3 class="title is-5 is-subtitle has-text-centered" style="padding-top: 1em">
    Hotel information
  </h3>
  <div class="">
    <ul class="has-text-centered">
      <li>SpringHill Suites Columbus OSU</li>
      <li>1421 Olentangy River Rd, Columbus, OH 43212</li>
      <li>Rate available for Friday, Saturday, or Both!</li>
      <li>Book by May 24th, 2022</li>
    </ul>
    <br />
  </div>
  <div class="columns">
    <div class="column is-one-half has-text-centered">
      <div class="has-box">
        <p><b>King Bed + Pullout</b></p>
        <p>$139</p>
      </div>
    </div>
    <div class="column is-one-half has-text-centered">
      <div class="has-box">
        <p><b>2 Queen Beds</b></p>
        <p>$159</p>
      </div>
    </div>
  </div>

  <h3 class="title is-5 is-subtitle has-text-centered" style="padding-top: 1em">
    Why should I use the hotel block?
  </h3>
  <div class="content is-normal">
    <ul class="content is-normal">
      <li>
        We're providing transportation to and from the venue from this hotel!
      </li>
      <li>
        We'll have welcome bags waiting for you with all the helpful information
        you'll need and goodies!
      </li>
      <li>We worked to get a great guaranteed rate set!</li>
    </ul>
  </div>

  <div style="height: 300px"></div>
</template>

<script>
import { ApiService } from "../services/apiService";

export default {
  methods: {
    clickHotelLink() {
      ApiService.postEvent({
        page: "Hotel Link",
        userAgent: navigator.userAgent,
        date: new Date(),
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.has-box
  border: 1px solid gray
  border-radius: 1em
</style>