import axios from 'axios'

export class ApiService {
    static baseUrl = "https://wedding.korramatt.com/api/";

    static postEvent(event) {
        return axios.post(this.baseUrl + 'events', event);
    }

    static getEvents() {
        return axios.get(this.baseUrl + "events");
    }
}