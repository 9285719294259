<template>
  <div>
    <div class="has-text-centered">
      <h1 class="title is-1 has-text-cursive bigger-space">FAQ</h1>
    </div>
    <div>
      <div v-for="(faq, index) in faqs" :key="index">
        <p class="question"><b>Q: &nbsp;</b> {{ faq.Q }}</p>
        <p><b>A: &nbsp;</b>{{ faq.A }}</p>
        <a class="gold-link" v-if="faq.Link" :href="faq.Link">{{ faq.Link }}</a>
        <img v-if="faq.Image" :src="faq.Image" />
        <hr v-show="index < faqs.length - 1" />
      </div>

      <div style="min-height: 200px"></div>
    </div>
  </div>
</template>
<script>
import data from "../../public/faq.json";

export default {
  data() {
    return {
      faqs: data.faqs,
    };
  },
  components: {},
};
</script>
<style scoped>
h1.bigger-space {
  margin-bottom: 1.5em;
}

.question {
  margin-bottom: 0.5em;
}
</style>