import { createRouter, createWebHistory } from "vue-router";
import { ApiService } from "../services/apiService";
import Home from "../views/Home.vue";
import Faq from "../views/Faq.vue";
import OurStory from "../views/OurStory.vue";
import PartyPeople from "../views/PartyPeople.vue";
import ColumbusTravel from "../views/ColumbusTravel.vue";
import Status from "../views/Status.vue";
import EngagementPhotos from "../views/EngagementPhotos.vue";
import Registry from "../views/Registry.vue";
import BridalShower from "../views/BridalShower.vue";
import WelcomeDinner from "../views/WelcomeDinner.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Story",
    name: "Our Story",
    component: OurStory
  },
  {
    path: "/Party",
    name: "Party People",
    component: PartyPeople
  },
  {
    path: "/Faq",
    name: "Faq",
    component: Faq
  },
  {
    path: "/Columbus",
    name: "Columbus",
    component: ColumbusTravel
  },
  {
    path: "/Status",
    name: "Status",
    component: Status
  },
  {
    path: "/EngagementPhotos",
    name: "EngagementPhotos",
    component: EngagementPhotos,
  },
  {
    path: "/Registry",
    name: "Registry",
    component: Registry,
  },
  {
    path: "/Shower",
    name: "BridalShower",
    component: BridalShower,
  },
  {
    path: "/WelcomeDinner",
    name: "WelcomeDinner",
    component: WelcomeDinner,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  ApiService.postEvent({
    page: to.fullPath,
    userAgent: navigator.userAgent,
    date: new Date()
  });
})

export default router;
