<template>
  <div class="home has-text-centered">
    <h1 class="title is-1 has-text-cursive">Welcome Dinner</h1>
    <br />
    <p>All out-of-town guests are invited to attend a Welcome Dinner the night before the wedding! If you would like to attend the welcome dinner please let us know your meal selection on your RSVP card.  You can contact Korra or Matt with any questions.</p>

    <div style="margin: 1.5em">
      <div class="columns" style="border: 1px solid gray; border-radius: 1em">
        <div class="column is-one-third">
          <img src="../../public/RingIcon.png" width="60" height="60" />
          <br />
          <h4 class="title is-2 has-text-cursive">What</h4>
          <p>A welcome dinner for out of town guests and bridal party members</p>
        </div>

        <div class="column is-one-third">
          <img src="../../public/ClockIcon.png" width="60" height="60" />
          <br />
          <h4 class="title is-2 has-text-cursive">When</h4>
          <p>Friday June 24, 2022</p>
          <p>6:00 pm</p>
        </div>

        <div class="column is-one-third">
          <img src="../../public/MapIcon.png" width="60" height="60" />
          <br />
          <h4 class="title is-2 has-text-cursive">Where</h4>
          <p>Graystone</p>
          <p> 544 S Front St, Columbus, OH 43215</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "WelcomeDinner",
};
</script>

<style scoped>
p {
  margin-bottom: 0.15em;
}
</style>
