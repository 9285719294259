<template>
  <div>
    <h1 class="title is-1 has-text-centered has-text-cursive">Our Story</h1>
    <br />
    <div>
      <paragraph-photo
        v-for="(item, index) in story"
        :key="index"
        :text="item.Text"
        :src="item.Image"
        :isOdd="index % 2 ? '1' : ''"
      ></paragraph-photo>
    </div>
  </div>
</template>

<script>
import ParagraphPhoto from "../components/ParagraphPhoto.vue";
import data from "../../public/ourstory.json";

export default {
  name: "OurStory",
  components: {
    ParagraphPhoto,
  },
  data() {
    return {
      story: data.story,
    };
  },
};
</script>