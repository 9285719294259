<template>
    <div class="has-text-centered">
        <h1 class="has-text-cursive title is-1">Registry</h1>
        <p>
            We are registered at Crate and Barrel and Bed Bath and Beyond! Click below to see each registry!
        </p>
        
        <div class="columns">
            <div class="column is-one-half">
                <h2 class="title is-3 has-text-cursive">
                    Crate and Barrel
                </h2>
                <a href="https://www.crateandbarrel.com/gift-registry/korra-shay-richards/r6372375"
                    target="_blank"                
                    @click="clickRegistry('crateandbarrel')"
                    class="button is-outline is-dark is-large">              
                    View Registry
                </a>
            </div>
            <div class="column is-one-half">
                <h2 class="title is-3 has-text-cursive">
                    Bed Bath and Beyond
                </h2>
                <a href="https://www.bedbathandbeyond.com/store/giftregistry/viewregistryguest/551188512"
                    target="_blank"
                    @click="clickRegistry('bedbathbeyond')"
                    class="button is-outline is-dark is-large">
                    View Registry
                </a>
            </div>           
        </div>
        <div style="height: 100px">
        </div> 
    </div>
</template>
<script>
import { ApiService } from "../services/apiService";

export default {
  methods: {
    clickRegistry(registryName) {
      ApiService.postEvent({
        page: `Registry: ${registryName}`,
        userAgent: navigator.userAgent,
        date: new Date(),
      });
    },
  },
};
</script>