<template>
  <div>
    <router-link to="/">
      <div id="banner-wrapper">
        <img class="banner-image" src="../../public/Banner.png" width="100%" />
      </div>
    </router-link>

    <div
      class="
        column
        is-hidden-touch
      "
    >
      <div class="tabs is-centered is-boxed is-fullwidth">
        <ul>
          <li
            v-for="(link, index) in links"
            :key="index"
            :class="{ 'is-active': isActive(link.name) }"
          >
            <router-link :to="link.href">{{ link.name }}</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="column is-hidden-desktop">
      <div class="dropdown" :class="{ 'is-active': showMenuTiles }">
        <div class="dropdown-trigger" @click="showMenuTiles = !showMenuTiles">
          <button class="button">
            <span class="menu-text">Menu</span>
            <span class="icon is-small">
              <i v-show="!showMenuTiles" class="fa fa-bars"></i>
              <i v-show="showMenuTiles" class="fa fa-xmark"></i>
            </span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <router-link :to="link.href" v-for="(link, index) in links" :key="index" @click="showMenuTiles = false">
              <div
                class="dropdown-item"
                :class="{ 'is-active': isActive(link.name) }"
              >{{link.name}}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenuTiles: false,
      links: [
        { href: "/", name: "Home" },
        { href: "/Story", name: "Our Story" },
        { href: "/Party", name: "Party People" },
        { href: "/FAQ", name: "FAQ" },
        { href: "/Columbus", name: "Columbus Travel" },
        { href: "/EngagementPhotos", name: "Engagement Photos" },
        { href: "/Registry", name: "Registry" },
      ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name?.toLowerCase();
    },
  },
  methods: {
    isActive(route) {
      return route.toLowerCase() === this.currentRouteName;
    },
  },
};
</script>

<style scoped>
#banner-wrapper,
img.banner-image {
  width: 100%;
}
.dropdown {
  width: 100%;
}
.dropdown-trigger {
  width: 100%;
}
button {
  width: 100%;
}
.dropdown-menu {
  width: 100%;
}

.dropdown-content {
  width: 100%;
}
.dropdown-item {
  width: 100%;
}

.menu-text {
    font-family: "Martel", serif !important;
    font-weight: bold;
}
</style>