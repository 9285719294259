<template>
  <div>
    <h1 class="title is-1 has-text-centered has-text-cursive">Party People</h1>
    <div class="columns is-multiline">
      <div class="column is-half-desktop is-full">
        <img src="../../public/partyPeopleElla.png" />
      </div>
      <div class="column is-half-desktop is-full">
        <img src="../../public/partyPeopleHelena.png" />
      </div>
      <div class="column is-half-desktop is-full">
        <img src="../../public/partyPeopleAlex.png" />
      </div>
      <div class="column is-half-desktop is-full">
        <img src="../../public/partyPeopleAli.png" />
      </div>
      <div class="column is-half-desktop is-full">
        <img src="../../public/partyPeopleBrea.png" />
      </div>
      <div class="column is-half-desktop is-full">
        <img src="../../public/partyPeopleColby.png" />
      </div>
      <div class="column is-half-desktop is-full">
        <img src="../../public/partyPeopleMariaM.png" />
      </div>
      <div class="column is-half-desktop is-full">
        <img src="../../public/partyPeopleMariaN.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
        