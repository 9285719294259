<template>
  <nav-bar></nav-bar>
  <div class="columns">
    <div
      class="column is-three-fifths-widescreen is-offset-one-fifth-widescreen"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
export default {
  components: {
    NavBar,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Martel:wght@200&display=swap");

.is-horizontal-center {
  justify-content: center;
}

@font-face {
  font-family: "brittanyScript";
  src: url("assets/BrittanySignature.ttf") format("truetype");
}

@font-face {
  font-family: "twisterScript";
  src: url("assets/Twister.ttf") format("truetype");
}

body {
  font-family: "Martel", serif !important;
}

.has-text-cursive {
  font-family: twisterScript !important;
  font-weight: lighter !important;
  margin-top: 5%;
}

h1.has-text-cursive {
  font-size: 5em !important;
}

a.gold-link {
  color: goldenrod;
}
</style>
